<template>
  <div v-if="earnPointsPopup" class="d-flex flex-column align-items-center text-center how-to-win-container">
    <img @click="closeIconClicked()" class="close-icon" :src="require(`../assets/game/close-icon.png`)">
    <div class="d-flex flex-column align-items-center opacity-container">
      <div class="header-text mt-3">Point Breakdown:</div>
      <div class="mt-2 col-10">The points allocated are based on the actual match result.</div>
      <table v-if="this.$route.name.includes('Rugby')" class="table table-borderless">
        <tbody>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Login
          </th>
          <td class="table-number">{{ 5 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Predict
          </th>
          <td class="table-number">{{ 10 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Correct Team
          </th>
          <td class="table-number">{{ 5 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Correct Win Margin (within 5)
          </th>
          <td class="table-number">{{ 5 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Exact Margin
          </th>
          <td class="table-number">{{ 10 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Draw
          </th>
          <td class="table-number">{{ 30 }}</td>
        </tr>
        </tbody>
      </table>
      <table v-if="this.$route.name.includes('Soccer')" class="table table-borderless">
        <tbody>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Login
          </th>
          <td class="table-number">{{ 5 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Predict
          </th>
          <td class="table-number">{{ 10 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Correct Home Score
          </th>
          <td class="table-number">{{ 5 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Correct Away Score
          </th>
          <td class="table-number">{{ 5 }}</td>
        </tr>
        <tr class="d-flex flex-row justify-content-start">
          <th class="d-flex flex-row justify-content-start breakdown-description">
            Correct Result
          </th>
          <td class="table-number">{{ 10 }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "HowToWin",
  computed: {
    ...mapState(['earnPointsPopup'])
  },
  methods: {
    ...mapMutations(['setEarnPointsPopup']),
    closeIconClicked() {
      this.setEarnPointsPopup(false)
    }
  },
}
</script>

<style scoped>
.how-to-win-container {
  position: relative;
}

.opacity-container {
  opacity: 0.9;
  background-color: #FFFFFF;
  font-size: 14px;
}

.close-icon {
  position: absolute;
  right: -10px;
  top: -15px;
  width: 25px;
  z-index: 1;
}

.header-text {
  font-weight: 600;
}

table {
  width: 90%;
  line-height: 1.1;
}

.breakdown-description {
  width: 81%;
  font-size: 13px;
  font-weight: 500;
}

.table-number {
  font-weight: 700;
}

@media screen and (max-width: 375px) {
  .breakdown-description {
    font-size: 12px;
  }
}

</style>
