<template>
  <div v-if="teamInsights" class="d-flex flex-column align-items-center justify-content-center team-stats-popup col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
    <img @click="this.setRugbyInsightsPopup(false)" class="col-2 close-popup-img" src="../assets/game/exit-icon.png">
    <div v-if="loadingSpinner === true" class="d-flex justify-content-center m-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="col-12" v-if="loadingSpinner === false">
      <div class="d-flex flex-row align-items-center text-center col-12 mt-4 mb-4">
        <div class="col-4 statistics-header">
          Statistics
        </div>
        <div class="col-4">
          <img class="badge-icon" :src="this.findImageModule(teamInsights[0].Name)">
        </div>
        <div class="col-4">
          <img class="badge-icon" :src="this.findImageModule(teamInsights[1].Name)">
        </div>
      </div>
      <div class="d-flex flex-row col-12">
        <div class="col-4 statistic-given-text">
          Last 5 Matches
        </div>
        <div class="d-flex flex-row justify-content-center col-4 matches-streak-container">
          <div class="d-flex flex-row col-4 align-items-center justify-content-center text-center">
            <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[0].RecentForm)[0])[0]} text-center col-2`">
              {{ Array.from(teamInsights[0].RecentForm)[0] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[0].RecentForm)[1])[0]} text-center col-2`">
              {{ Array.from(teamInsights[0].RecentForm)[1] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[0].RecentForm)[2])[0]} text-center col-2`">
              {{ Array.from(teamInsights[0].RecentForm)[2] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[0].RecentForm)[3])[0]} text-center col-2`">
              {{ Array.from(teamInsights[0].RecentForm)[3] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[0].RecentForm)[4])[0]} text-center col-2`">
              {{ Array.from(teamInsights[0].RecentForm)[4] }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center text-center col-4 matches-streak-container">
          <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[1].RecentForm)[0])[0]} text-center col-2`">
            {{ Array.from(teamInsights[1].RecentForm)[0] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[1].RecentForm)[1])[0]} text-center col-2`">
            {{ Array.from(teamInsights[1].RecentForm)[1] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[1].RecentForm)[2])[0]} text-center col-2`">
            {{ Array.from(teamInsights[1].RecentForm)[2] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[1].RecentForm)[3])[0]} text-center col-2`">
            {{ Array.from(teamInsights[1].RecentForm)[3] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(Array.from(teamInsights[1].RecentForm)[4])[0]} text-center col-2`">
            {{ Array.from(teamInsights[1].RecentForm)[4] }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center col-12 mt-2 mb-2">
      <div class="col-4 statistic-given-text">
        Current Position
      </div>
      <div class="col-4 position-text text-center">
        {{ teamInsights[0].TeamRank }}
      </div>
      <div class="col-4 position-text text-center">
        {{ teamInsights[1].TeamRank }}
      </div>
    </div>
    <div class="d-flex flex-row align-items-center col-12 mt-2 mb-4">
      <div class="col-4 statistic-given-text">
        {{ this.selectedSportLeague.toUpperCase() + ' Results' }}
      </div>
      <div class="d-flex flex-row justify-content-center col-4">
        <div class="green-circle text-center">
          {{ teamInsights[0].Wins }}
        </div>
        <div class="grey-circle text-center">
          {{ teamInsights[0].Ties }}
        </div>
        <div class="red-circle primary-bg-colour text-center">
          {{ teamInsights[0].Losses }}
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center col-4">
        <div class="green-circle text-center">
          {{ teamInsights[1].Wins }}
        </div>
        <div class="grey-circle text-center">
          {{ teamInsights[1].Ties }}
        </div>
        <div class="red-circle primary-bg-colour text-center">
          {{ teamInsights[1].Losses }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "RugbyTeamInsights",
  inheritAttrs: false,
  computed: {
    ...mapState(['selectedSportLeague']),
  },
  props: ['teamInsights'],
  data() {
    return {
      loadingSpinner: false,
    }
  },
  methods: {
    ...mapMutations(['setRugbyInsightsPopup']),
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`@/assets/teamBadges/rugby/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    displayMatchResultAbbreviationCircle(matchResult) {
      const resultClasses = {
        'D': 'grey-circle',
        'W': 'green-circle',
        'L': 'red-circle',
      };
      return [resultClasses[matchResult]];
    },
    loadData() {
      if (this.teamInsights === undefined) {
        this.loadingSpinner = true;

        const checkInsights = () => {
          if (this.teamInsights !== undefined) {
            this.loadingSpinner = false;
          } else {
            setTimeout(checkInsights, 100);
          }
        };
        checkInsights();
      }
    },
  },
  beforeMount() {
    this.loadData()
  }
}
</script>

<style scoped>
.team-stats-popup {
  background-color: #e8e8e8;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-popup-img {
  position: fixed;
  right: -25px;
  top: -26px;
}

.statistics-header {
  font-size: 22px;
  font-weight: 600;
}

.badge-icon {
  width: 40px;
}

.statistic-given-text {
  padding-left: 6px;
  font-size: 13px;
}

.matches-streak-container {
  margin-left: 3px;
  margin-right: 3px;
}

.green-circle, .grey-circle, .red-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  color: #FFFFFF;
  font-size: 10.5px;
  margin: 1.5px;
}

.green-circle {
  background-color: #00D30A;
}

.grey-circle {
  background-color: #777777;
}

.red-circle {
  background-color: #F42525;
}

</style>
