<template>
  <div class="d-flex flex-column align-items-center body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column text-center col-12">
      <img src="../assets/game/main-banner.png">
    </div>
    <div class="mt-2 col-12">
      <div class="d-flex flex-row justify-content-around col-12 text-center border">
        <div @click="openCloseEarnPointsPopUp()"
             class="d-flex flex-row align-items-center justify-content-center earn-points-button">
          How to Win
        </div>
        <div @click="autoPredictButtonClicked(fixturesArray)" class="d-flex flex-row align-items-center justify-content-center auto-predict-button">
          Auto Predict
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-around round-date-time col-12 mt-3 p-1">
      <div>{{ formattedDate }}</div>
      <div>{{ formattedTime }}</div>
    </div>
    <div class="text-center col-11 make-prediction-team-margin-container pb-3"
         v-for="(fixture, index) in fixturesArray" :key="index">
      <div class="match-container" v-if="checkMatchNotStarted(fixture)">
        <div class="pt-2 winner-margin-text">
          Select a winner and input a win margin
        </div>
        <div class="d-flex flex-row justify-content-center team-select-container pt-1">
          <div @click="teamInsightButtonClicked(fixture.Team1Id, fixture.Team2Id)" class="more-stats-icon secondary-text">
            <div class="text-more-stats-icon">i</div>
          </div>
          <div @click="teamSelectedToWin(fixture, fixture.Team1Id, 1)"
               :class=" fixture.teamNumber === 1 || fixture.WinningTeam === fixture.Team1Id ? 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
            <img class="col-8 team-badge-img" :src="this.findImageModule(fixture.Team1Name)[0]"/>
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team1Name)[1] }}</div>
          </div>
          <div @click="resultDrawSelected(fixture)"
               class="d-flex flex-column justify-content-center draw-container p-2 mt-1">
            <div
                :class="fixture.teamNumber === null || fixture.WinningTeam === null  ? 'd-flex flex-column justify-content-center align-content-center align-items-center secondary-text draw-box-selected' : 'd-flex flex-column justify-content-center align-content-center align-items-center draw-box-default'">
              draw
            </div>
          </div>
          <div @click="teamSelectedToWin(fixture, fixture.Team2Id, 2)"
               :class=" fixture.teamNumber === 2 || fixture.WinningTeam === fixture.Team2Id  ?'d-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
            <img class="col-8 team-badge-img" :src="this.findImageModule(fixture.Team2Name)[0]"/>
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team2Name)[1] }}</div>
          </div>
        </div>
        <div v-if="fixture.Draw || fixture.WinMargin" class="d-flex flex-row justify-content-center pt-2">
          <button @click="editPredictionButtonClicked(fixture)"
                  class="edit-prediction-button">
            Edit Predictions
          </button>
        </div>
        <div class="d-flex flex-row justify-content-center pt-2">
          <div
              class="d-flex flex-column justify-content-center win-margin-container"
              v-if="fixture.Draw === false || fixture.Draw === null">
            {{ fixture.WinMargin }}
          </div>
        </div>
        <div v-if="fixture.isSelected && autoPredictSelected === false" class="d-flex flex-row align-items-center justify-content-center pt-2">
          <img class="col-1 m-2" src="../assets/game/left-arrow-icon.png">
          <div class="scrollable-container col-8">
            <div @click="setWinMargin(fixture, option)" class="option" v-for="option in options">
              {{ option }}
            </div>
          </div>
          <img class="col-1 m-2" src="../assets/game/right-arrow-icon.png">
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center pt-3 col-12 submit-predictions-container">
      <button @click="submitPredictionsButtonClicked" :disabled="submitButtonDisabled"
              class="submit-predictions-button">
        Submit Predictions
      </button>
      <div v-if="successfulSubmissionPopup"
           class="d-flex flex-column text-center align-items-center prediction-submitted-container">
        <div class="d-flex flex-column align-items-center background-opacity-container">
          <div class="col-10 submission-header-text mt-3">
            Prediction Submitted Successfully:
          </div>
          <div class="col-11 submission-secondary-text mt-2">
            Thanks for predicting the score for this round. All the points you earn will be entered into the daily
            prize draw!
          </div>
          <div class="col-11 submission-secondary-text mt-2">
            Predict the worlds greatest leagues with Vodacom Fantasy Predictor for a chance to WIN more airtime daily!
          </div>
          <button v-if="subscriptionStatus === 'Subscribed'" class="mt-3 col-11 join-now-button"
                  @click="predictWinClicked">PLAY PREDICT & WIN
          </button>
          <button v-if="subscriptionStatus !== 'Subscribed'" class="mt-3 col-11 join-now-button" @click="onJoinNowClicked">
            PLAY VIP for R3/day
          </button>
          <button class="mt-3 mb-2 col-11 home-button" @click="$router.back()">
            Return Home
          </button>
          <div @click="termsConditionsClicked()" v-if="subscriptionStatus !== 'Subscribed'" class="text-decoration-underline term-conditions-text mb-3">T&C Apply | Subscription Service.</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center how-to-win-container">
      <HowToWin/>
    </div>
    <div v-if="rugbyInsightsPopup">
      <RugbyTeamInsights :teamInsights="teamLeagueStatistics"/>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import GameDataService from "@/services/gameDataService";
import UtilsService from "@/services/utilsService";
import HowToWin from "@/components/HowToWin";
import RugbyTeamInsights from "@/components/RugbyTeamInsights";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "MakePredictionsRugby",
  components: {HowToWin, RugbyTeamInsights},
  computed: {
    ...mapState(['isLoading', 'roundEntered', 'user', 'subscriptionStatus', 'rugbyInsightsPopup', 'selectedSportLeague'])
  },
  data() {
    return {
      successfulSubmissionPopup: false,
      submitButtonDisabled: false,
      fixturesArray: undefined,
      formattedTime: undefined,
      formattedDate: undefined,
      pointsScoringBreakdown: undefined,
      noTeamSelectedError: false,
      displayClubName: false,
      autoPredictSelected: undefined,
      teamLeagueStatistics: undefined,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setRoundEntered', 'setEarnPointsPopup', 'setRugbyInsightsPopup']),
    dateConversion(fixtures) {
      this.formattedDate = moment(fixtures[0].StartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtures[0].StartDate).format('H:mm');
    },
    autoPredictButtonClicked(fixtures) {
      fixtures.forEach((match) => {
        this.setWinMargin(match, this.randomizeNumber(1, 30))
        this.teamSelectedToWin(match, this.setWinningTeamId(match))
      })
      this.autoPredictSelected = true
    },
    randomizeNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    setWinningTeamId(match) {
      const teamIdOptions = [match.Team1Id, match.Team2Id];
      const randomIndex = Math.floor(Math.random() * teamIdOptions.length);
      return teamIdOptions[randomIndex];
    },
    predictWinClicked() {
      window.open(`${environmentConfig.predictorHost}/?fantasyToken=${this.user.token}`, '_self');
    },
    editPredictionsClicked() {
      this.submitButtonDisabled = false;
      this.successfulSubmissionPopup = false;
    },
    homeButtonClicked() {
      this.setRoundEntered(true);
      this.$router.push('/landing');
    },
    async onJoinNowClicked() {
      await UtilsService.doiOne(this.user.msisdn);
      window.open(`${environmentConfig.entryPortalHost}/?fantasyToken=${this.user.token}`, '_self');
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`@/assets/teamBadges/rugby/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/teamBadges/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    openCloseEarnPointsPopUp() {
      this.setEarnPointsPopup(true)
    },
    setWinMargin(fixture, winMargin) {
      fixture.WinMargin = winMargin
      fixture.isSelected = true
      fixture.Draw = false;
      fixture.isSelected = false;
    },
    teamSelectedToWin(fixture, teamId, teamNumber) {
      this.autoPredictSelected = false
      fixture.teamNumber = teamNumber
      fixture.isSelected = true;
      fixture.teamId = teamId;
      fixture.draw = 0;
      fixture.WinningTeam = teamId;
      this.noTeamSelectedError = false;
      fixture.winMarginText = true;
    },
    resultDrawSelected(fixture) {
      fixture.teamNumber = null;
      fixture.draw = 1;
      fixture.teamId = null;
      fixture.WinMargin = null;
      fixture.isSelected = false;
      fixture.WinningTeam = null;
      this.noTeamSelectedError = false;
    },
    addRoundFixturesDefaultWinMargin(fixtures) {
      this.fixturesArray = [];
      fixtures.forEach((fixture) => {
        fixture.WinMargin = null;
        fixture.isSelected = undefined;
        fixture.teamNumber = undefined;
        fixture.draw = null;
        this.fixturesArray.push(fixture);
      });
    },
    editPredictionButtonClicked(fixture) {
      fixture.Draw = undefined;
      fixture.Points = 0;
      fixture.isSelected = null;
      fixture.teamNumber = undefined;
      fixture.teamId = null;
      fixture.WinMargin = null;
      fixture.WinningTeam = undefined;
    },
    async submitPredictionsButtonClicked() {
      this.submitButtonDisabled = true;
      const fixture = {};
      await this.fixturesArray.asyncForEach(async (match) => {
        this.checkForWinMargin(match);
        this.checkForNoSelection(match)
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.roundId;
        fixture.rugbyFixtureId = match.FixtureID || match.RugbyFixtureID;
        fixture.winMargin = match.WinMargin;
        fixture.teamId = match.teamId;
        fixture.draw = match.draw;

        await GameDataService.enterRugbyRoundPredictions(fixture, this.user.msisdn);
      });
      this.successfulSubmissionPopup = true;
    },
    checkForWinMargin(match) {
      if (match.teamId && match.WinMargin === null) {
        match.WinMargin = 1;
      }
    },
    checkForNoSelection(match) {
      if (!match.teamId && !match.WinMargin) {
        match.draw = 1;
      }
    },
    setWinningTeamIdAndWinMargin() {
      this.fixturesArray.forEach((fixture) => {
        fixture.teamId = fixture.WinningTeam
      })
    },
    async checkIfRoundPreviouslyEntered() {
      await this.loadLeagueFixtures();
      await this.loadUserFixtures();
    },
    async loadLeagueFixtures() {
      this.leagueFixtures = await GameDataService.getLeagueFixtures(
          `${this.user.msisdn}`,
          `${this.$route.params.roundId}`
      );
    },
    async loadUserFixtures() {
      const userFixturesEnteredResponse = await GameDataService.getPlayerRoundPredictions(
          `${this.user.msisdn}`,
          `${this.$route.params.roundId}`
      );

      if (userFixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.fixturesArray = userFixturesEnteredResponse.data.roundPredictions;
        this.setWinningTeamIdAndWinMargin();
      } else {
        this.addRoundFixturesDefaultWinMargin(this.leagueFixtures.data.fixtures);
      }
    },
    loadPointsScoringBreakdown() {
      this.pointsScoringBreakdown = this.leagueFixtures.data.pointsScoringBreakdown[0];
    },
    checkRoundId() {
      this.roundId = this.$route.params.roundId
    },
    scrollerSetMargin() {
      this.options = [];
      for (let i = 1; i < 121; i++) {
        this.options.push(i);
      }
    },
    async teamInsightButtonClicked(teamOneId, teamTwoId) {
      await this.setTeamLeagueStatistics(teamOneId, teamTwoId);
      this.setRugbyInsightsPopup(true)
    },
    async setTeamLeagueStatistics(teamOneId, teamTwoId) {
      let homeTeamInsightsResponse = await GameDataService.getTeamInsights(this.selectedSportLeague, teamOneId);
      let awayTeamInsightsResponse = await GameDataService.getTeamInsights(this.selectedSportLeague, teamTwoId);
      this.teamLeagueStatistics = [homeTeamInsightsResponse.data, awayTeamInsightsResponse.data]
    },
    termsConditionsClicked() {
      window.open(`${environmentConfig.termsConditionsHost}`, '_self');
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkIfRoundPreviouslyEntered()
    this.dateConversion(this.fixturesArray);
    this.checkRoundId();
    this.scrollerSetMargin()
    this.loadPointsScoringBreakdown();
    this.setIsLoading(false);
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 120px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
  position: relative;
}

.scrollable-container {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}

.round-date-time {
  font-weight: 600;
  height: 30px;
  font-size: 15px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.team-name {
  font-size: 10px;
}

.option {
  background-color: #2D343B;
  width: 50px !important;
  height: 40px;
  margin-right: 0.6rem;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  font-size: 1.8rem;
}

.team-select-container {
  position: relative;
}

.edit-prediction-button {
  border: 0;
  font-size: 14px;
  background-color: #2D343B;
  color: #FFFFFF;
  height: 25px;
}

.win-margin-container {
  height: 25px;
  width: 80px;
  color: white;
  font-size: 12px;
  background-color: #2D343B;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.earn-points-button, .auto-predict-button {
  font-size: 16px;
  color: #FFFFFF;
  height: 30px;
  font-weight: 600;
  background-color: #EF151C;
  width: 46%;
}

.winner-margin-text {
  font-size: 14px;
  font-weight: 600;
}

.team-badge-container-default {
  border: 2px solid black;
  height: 80px;
  width: 100px;
}

.team-badge-container-selected {
  border: 2px solid red;
  height: 80px;
  width: 100px;
  background: #FFFFFF;
}

.draw-container {
  height: 70px;
}

.draw-box-selected {
  border: 2px solid red;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
  background: #FFFFFF;
}

.draw-box-default {
  border: 2px solid black;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
}

.win-margin-box {
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}

.win-margin-box input {
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}

::placeholder {
  color: white;
}

.match-container {
  padding-bottom: 15px;
  border-bottom: 1px solid gray;
}

table {
  width: 90%;
}

tr {
  border-bottom: .2px solid gray;
}

.submit-predictions-button {
  height: 50px;
  font-size: 16px;
  border: 0;
  color: #FFFFFF;
  background-color: #EF151C;
}

.submit-predictions-container {
  position: relative;
}

.prediction-submitted-container {
  position: absolute;
  left: 12.5%;
  bottom: 10px;
  width: 75%;
  border: 1px solid #bfa668;
}

.background-opacity-container {
  background-color: #FFFFFF;
  opacity: 0.95;
}

.submission-header-text {
  font-weight: 700;
  font-size: 17px;
  border-bottom: .5px solid #FFFFFF;
}

.join-now-button {
  background-color: #bfa668;
  color: #FFFFFF;
  border: 0;
  height: 45px;
  font-size: 16px;
}

.home-button {
  height: 45px;
  font-size: 16px;
  background-color: #FFFFFF;
  color: #bfa668;
  border: 1px solid #bfa668;
}

.how-to-win-container {
  position: absolute;
  width: 85%;
  top: 30%;
}

.more-stats-icon {
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #bfa668;
}

.text-more-stats-icon {
  position: absolute;
  right: 7.5px;
  top: -2.5px;
  font-size: 15px;
  font-weight: 600;
  color: #bfa668;
}

.term-conditions-text {
  font-size: 12px;
}
</style>
